import React from "react";
import { NavLink } from "react-router-dom";
import { FaUsers, FaUserNurse, FaUserTie } from "react-icons/fa6";
import {
  NotificationIcon,
} from "../SvgIcons";

const Agent = ({ setShowOffcanvas }) => {
  const navLinks = [
    { to: "/manage-user", icon: <FaUsers />, text: "Users" },
    { to: "/manage-officer", icon: <FaUserNurse />, text: "Officers" },
    { to: "/manage-agents", icon: <FaUserTie />, text: "Agents" },
    { to: "/notifications", icon: <NotificationIcon />, text: "Notifications" },
  ];

  const handleNavLinkClick = () => {
    setShowOffcanvas(false);
  };

  return (
    <div className="d-flex flex-column flex-shrink-0 h-100 sideBar">
      <ul className="nav nav-pills sidebarNav flex-column mb-auto">
        {navLinks.map((link, index) => (
          <li className="nav-item" key={index} onClick={handleNavLinkClick}>
            <NavLink
              to={link.to}
              className="nav-link"
              activeClassName="active"
            >
              {link.icon}
              {link.text}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Agent;
