
import {
    Row,
    Button,
    Col,
    Form
} from "react-bootstrap";
import onGenerateReport from "../../services/Reports/GenerateReport";
import { useForm } from 'react-hook-form';
import DatePicker from "react-datepicker";
import React, { useState } from 'react';
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";


const GenerateReportModalForm = ({ id }) => {
    const { handleSubmit } = useForm();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [reportType, setReportType] = useState('custom');
    const [disableDownload, setDisableDownload] = useState(false);
    const [status, setStatus] = useState(null);

    let history = useHistory();

    const handleGenerateReport = () => {
        onGenerateReport({
            id,
            startDate: reportType === 'custom' ? startDate : null,
            endDate: reportType === 'custom' ? endDate : null,
            reportType,
            status,
            setDisableDownload,
            history,
        });
    };

    const handleStatusChange = (value) => {
        setStatus(value);
    };

    const ForDate = (props) => {
        return (
            <DatePicker
                className="w-100"
                selected={props.dateType ? startDate : endDate}
                onChange={(date) => {
                    props.dateType ? setStartDate(date) : setEndDate(date);
                }}
            />
        );
    };
    return (
        <>
            <Form
                className={"FormGenerateReport"}
                onSubmit={handleSubmit(handleGenerateReport)}
            >
                <div className={"modalBodyContent"}>
                    <h5>Report Period</h5>
                    <select value={reportType} onChange={(e) => setReportType(e.target.value)}>
                        <option value="custom">Date Range</option>
                        <option value="day">Today</option>
                        <option value="week">This Week</option>
                        <option value="month">This Month</option>
                    </select>
                </div>
                {reportType === 'custom' && (
                    <>
                        <div className={"modalBodyContent"}>
                            <h5>Start Date</h5>
                            <ForDate dateType={true} />
                        </div>
                        <div className={"modalBodyContent"}>
                            <h5>End Date</h5>
                            <ForDate dateType={false} />
                        </div>
                    </>
                )}
                <div className="modalBodyContent">
                    <h5>Status</h5>
                    <div className="d-flex align-items-center">
                        <Form.Check
                            type="radio"
                            id="passed"
                            label="Passed"
                            name="status"
                            value={10}
                            checked={status === 10}
                            onChange={() => handleStatusChange(10)}
                            className="me-3"
                        />
                        <Form.Check
                            type="radio"
                            id="violation"
                            label="Violation"
                            name="status"
                            value={20}
                            checked={status === 20}
                            onChange={() => handleStatusChange(20)}
                        />
                    </div>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Button
                            disabled={disableDownload ? true : false}
                            type={"submit"}
                            className={"btn btn-green-theme w-100 mt-3"}
                        >
                            {disableDownload ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{" "}
                                    Downloading
                                </>
                            ) : (
                                `Download Tests`
                            )}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default GenerateReportModalForm;