import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Form, Label, Button, InputGroup, FormControl } from "react-bootstrap";
import logoIcon from "../../assets/images/logo-full.svg";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { EmptyLocalStorage, Login } from "../../services/auth/auth.service";
import { constants } from "../../utils/constants";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Signin() {
  const history = useHistory();
  const [pass, TogglePass] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [rightsYear, setRightsYear] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    await Login(data.email, data.password, data.role, "web", "xyz", rememberMe)
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          if (
            data.data.role === constants.ROLES.ROLE_AGENT ||
            data.data.role === constants.ROLES.ROLE_OFFICER
          ) {
            history.push("/manage-user");
          } else {
            history.push("/dashboard");
          }
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    let rightsDate = new Date();
    setRightsYear(rightsDate.getFullYear());
  }, []);

  //width={"164px"}
  return (
    <div className={"text-center authLayout"}>
      <Form className={"form-signin"} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <a href="/">
            <img
              className="img-fluid mb-4 p-0"
              src={logoIcon}
              alt="Imprint Logo"
              title="Imprint Logo"
            />
          </a>
        </div>
        <h1 className="h5 mb-3 font-weight-normal">Sign In</h1>
        <Form.Group className="mb-3">
          <Form.Control
            placeholder="Enter Email"
            id={"formSignInEmail"}
            {...register("email", {
              required: {
                value: "required",
                message: "Email is required",
              },
              maxLength: {
                value: 255,
                message: "Maximum length is 255",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Invalid email",
              },
            })}
            type="text"
          />
          {errors.email && (
            <span className={"validation-error pr-5"} role="alert">
              {errors.email.message}
            </span>
          )}

          {/*password*/}
          <div className={"pass-field"}>
            <InputGroup controlId="passowrd">
              <FormControl
                placeholder="Enter Password"
                autoComplete={"off"}
                {...register("password", {
                  minLength: {
                    value: 8,
                    message: "Minimum length is 8",
                  },
                  maxLength: {
                    value: 255,
                    message: "Maximum length is 255",
                  },
                })}
                type={pass ? "password" : "text"}
              />
              <Button
                variant="outline-secondary"
                id="button-addon2"
                onClick={() => TogglePass(!pass)}
              >
                {pass ? <FaEye /> : <FaEyeSlash />}
              </Button>
            </InputGroup>
            {errors.password && (
              <span className={"validation-error pr-5"} role="alert">
                {errors.password.message}
              </span>
            )}
          </div>
          {/*password*/}

          {/*<Form.Control*/}
          {/*    placeholder="Password"*/}
          {/*    id={"formSignInPassword"}*/}
          {/*    {...register('password', {*/}
          {/*        required: {*/}
          {/*            value: "required",*/}
          {/*            message: "Password is required"*/}
          {/*        },*/}
          {/*        minLength: {*/}
          {/*            value: 8,*/}
          {/*            message: "Minimum length is 8"*/}
          {/*        },*/}
          {/*        maxLength: {*/}
          {/*            value: 255,*/}
          {/*            message: "Maximum length is 255"*/}
          {/*        }*/}
          {/*    })}*/}
          {/*    type="password"*/}
          {/*/>*/}
          {/*{errors.password &&*/}
          {/*<span className={"validation-error"} role="alert">{errors.password.message}</span>}*/}
          <Form.Control
            placeholder="Select Role"
            id={"roleId"}
            {...register("role", {
              required: {
                value: "required",
                message: "Role is required",
              },
            })}
            as="select"
            className="roleDropdown"
          >
            <option value="">Login as</option>
            <option value={constants.ROLES.ROLE_ADMIN}>Admin</option>
            <option value={constants.ROLES.ROLE_OFFICER}>Officer</option>
            <option value={constants.ROLES.ROLE_AGENT}>Agent</option>
            <option value={constants.ROLES.ROLE_REPRESENTATIVE}>
              {" "}
              Representative
            </option>
          </Form.Control>
          {errors.role && (
            <span className={"validation-error"} role="alert">
              {errors.role.message}
            </span>
          )}
        </Form.Group>

        <Form.Group className="mb-3 checkbox" controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="Remember me"
            onChange={(e) => setRememberMe(!rememberMe)}
          />
        </Form.Group>
        <div className="d-grid gap-2">
          <Button
            variant="primary"
            size="lg"
            type="submit"
            className={"btn-green-theme"}
          >
            Submit
          </Button>
          <ul className={"list-inline mt-3 d-flex"}>
            <li className={"list-inline-item text-left"}>
              <NavLink to={"/forgot-password"}>Forgot Password</NavLink>
            </li>
            {/*<li className={"list-inline-item text-right ms-auto"}>
                                <NavLink to={"/sign-up"}>
                                    Sign Up
                                </NavLink>
                            </li>*/}
          </ul>
        </div>
        <p className="mt-5 mb-3 text-muted">
          &copy; All rights reserved by Agent Monitoring 2024
          {/* {rightsYear !== null ? rightsYear : ""} */}
        </p>
      </Form>
    </div>
  );
}

export default Signin;
