import React from "react";
import { NavLink } from "react-router-dom";
import {
  OrderIcon,
  NotificationIcon,
  ManageCategoryIcon,
  ManagepProductsIcon,
  ManageUsersIcon,
  ManageAgentsIcon,
  ManageVendorsIcon,
  ManagePaymentsIcon,
  ReportsIcon,
  SettingsIcon,
  HomeIcon,
  HelpIcon,
  ContactIcon,
} from "../SvgIcons";
import { constants } from "../../utils/constants";

import { FaUsers, FaUserNurse, FaUserTie } from "react-icons/fa6";

const Representative = ({ setShowOffcanvas }) => {
  return (
    <div className="d-flex flex-column flex-shrink-0 h-100 sideBar">
      <ul className="nav nav-pills sidebarNav flex-column mb-auto">
        <li className="nav-item" onClick={() => setShowOffcanvas(false)}>
          <NavLink
            to="/dashboard"
            className="nav-link"
            activeClassName="active"
          >
            <HomeIcon />
            Home
          </NavLink>
        </li>
        <li className="nav-item" onClick={() => setShowOffcanvas(false)}>
          <NavLink
            to="/manage-user"
            className="nav-link"
            activeClassName="active"
          >
            <FaUsers />
            Users
          </NavLink>
        </li>
        <li className="nav-item" onClick={() => setShowOffcanvas(false)}>
          <NavLink
            to="/notifications"
            className="nav-link"
            activeClassName="active"
          >
            <NotificationIcon />
            Notifications
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Representative;
